import React from 'react';
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <BsGithub onClick={(() => window.open('https://github.com/Bejarano03', '_blank'))}/>
    </div>
    <div>
      <FaFacebookF onClick={(() => window.open('https://www.facebook.com/profile.php?id=100006996857279', '_blank'))}/>
    </div>
    <div>
      <BsLinkedin onClick={(() => window.open('https://www.linkedin.com/in/marcobejarano/', '_blank'))}/>
    </div>
  </div>
);

export default SocialMedia;